/*
 * Licensed to the Apache Software Foundation (ASF) under one
 * or more contributor license agreements.  See the NOTICE file
 * distributed with this work for additional information
 * regarding copyright ownership.  The ASF licenses this file
 * to you under the Apache License, Version 2.0 (the
 * "License"); you may not use this file except in compliance
 * with the License.  You may obtain a copy of the License at
 *
 *   http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing,
 * software distributed under the License is distributed on an
 * "AS IS" BASIS, WITHOUT WARRANTIES OR CONDITIONS OF ANY
 * KIND, either express or implied.  See the License for the
 * specific language governing permissions and limitations
 * under the License.
 */

.osk {
    position: relative;
}

.guac-keyboard {
    display: inline-block;
    width: 100%;
    
    margin: 0;
    padding: 0;
    cursor: default;

    text-align: left;
    vertical-align: middle;
}

.guac-keyboard,
.guac-keyboard * {
    overflow: hidden;
    white-space: nowrap;
}

.guac-keyboard .guac-keyboard-key-container {
    display: inline-block;
    margin: 0.05em;
    position: relative;
}

.guac-keyboard .guac-keyboard-key {

    position: absolute;
    left:   0;
    right:  0;
    top:    0;
    bottom: 0;

    background: #444;

    border: 0.125em solid #666;
    -moz-border-radius:    0.25em;
    -webkit-border-radius: 0.25em;
    -khtml-border-radius:  0.25em;
    border-radius:         0.25em;

    color: white;
    font-size: 40%;
    font-weight: lighter;
    text-align: center;
    white-space: pre;

    text-shadow:  1px  1px 0 rgba(0, 0, 0, 0.25),
                  1px -1px 0 rgba(0, 0, 0, 0.25),
                 -1px  1px 0 rgba(0, 0, 0, 0.25),
                 -1px -1px 0 rgba(0, 0, 0, 0.25);

}

.guac-keyboard .guac-keyboard-key:hover {
    cursor: pointer;
}

.guac-keyboard .guac-keyboard-key.highlight {
    background: #666;
    border-color: #666;
}

/* Align some keys to the left */
.guac-keyboard .guac-keyboard-key-caps,
.guac-keyboard .guac-keyboard-key-enter,
.guac-keyboard .guac-keyboard-key-tab,
.guac-keyboard .guac-keyboard-key-lalt,
.guac-keyboard .guac-keyboard-key-ralt,
.guac-keyboard .guac-keyboard-key-alt-gr,
.guac-keyboard .guac-keyboard-key-lctrl,
.guac-keyboard .guac-keyboard-key-rctrl,
.guac-keyboard .guac-keyboard-key-lshift,
.guac-keyboard .guac-keyboard-key-rshift {
    text-align: left;
    padding-left: 0.75em;
}

/* Active shift */
.guac-keyboard.guac-keyboard-modifier-shift .guac-keyboard-key-rshift,
.guac-keyboard.guac-keyboard-modifier-shift .guac-keyboard-key-lshift,

/* Active ctrl */
.guac-keyboard.guac-keyboard-modifier-control .guac-keyboard-key-rctrl,
.guac-keyboard.guac-keyboard-modifier-control .guac-keyboard-key-lctrl,

/* Active alt */
.guac-keyboard.guac-keyboard-modifier-alt .guac-keyboard-key-ralt,
.guac-keyboard.guac-keyboard-modifier-alt .guac-keyboard-key-lalt,

/* Active alt-gr */
.guac-keyboard.guac-keyboard-modifier-alt-gr .guac-keyboard-key-alt-gr,

/* Active caps */
.guac-keyboard.guac-keyboard-modifier-caps .guac-keyboard-key-caps,

/* Active super */
.guac-keyboard.guac-keyboard-modifier-super .guac-keyboard-key-super {
    background: #882;
    border-color: #DD4;
}

.guac-keyboard .guac-keyboard-key.guac-keyboard-pressed {
    background: #822;
    border-color: #D44;
}

.guac-keyboard .guac-keyboard-group {
    line-height: 0;
}

.guac-keyboard .guac-keyboard-group.guac-keyboard-alpha,
.guac-keyboard .guac-keyboard-group.guac-keyboard-movement {
    display: inline-block;
    text-align: center;
    vertical-align: top;
}

.guac-keyboard .guac-keyboard-group.guac-keyboard-main {

    /* IE10 */
    display: -ms-flexbox;
    -ms-flex-align: stretch;
    -ms-flex-direction: row;

    /* Ancient Mozilla */
    display: -moz-box;
    -moz-box-align: stretch;
    -moz-box-orient: horizontal;
    
    /* Ancient WebKit */
    display: -webkit-box;
    -webkit-box-align: stretch;
    -webkit-box-orient: horizontal;

    /* Old WebKit */
    display: -webkit-flex;
    -webkit-align-items: stretch;
    -webkit-flex-direction: row;

    /* W3C */
    display: flex;
    align-items: stretch;
    flex-direction: row;

}

.guac-keyboard .guac-keyboard-group.guac-keyboard-movement {
    -ms-flex: 1 1 auto;
    -moz-box-flex: 1;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
}

.guac-keyboard .guac-keyboard-gap {
    display: inline-block;
}

/* Hide keycaps requiring modifiers which are NOT currently active. */
.guac-keyboard:not(.guac-keyboard-modifier-caps)
.guac-keyboard-cap.guac-keyboard-requires-caps,

.guac-keyboard:not(.guac-keyboard-modifier-shift)
.guac-keyboard-cap.guac-keyboard-requires-shift,

.guac-keyboard:not(.guac-keyboard-modifier-alt-gr)
.guac-keyboard-cap.guac-keyboard-requires-alt-gr,

/* Hide keycaps NOT requiring modifiers which ARE currently active, where that
   modifier is used to determine which cap is displayed for the current key. */
.guac-keyboard.guac-keyboard-modifier-shift
.guac-keyboard-key.guac-keyboard-uses-shift
.guac-keyboard-cap:not(.guac-keyboard-requires-shift),

.guac-keyboard.guac-keyboard-modifier-caps
.guac-keyboard-key.guac-keyboard-uses-caps
.guac-keyboard-cap:not(.guac-keyboard-requires-caps),

.guac-keyboard.guac-keyboard-modifier-alt-gr
.guac-keyboard-key.guac-keyboard-uses-alt-gr
.guac-keyboard-cap:not(.guac-keyboard-requires-alt-gr) {

    display: none;
    
}

/* Fade out keys which do not use AltGr if AltGr is active */
.guac-keyboard.guac-keyboard-modifier-alt-gr
.guac-keyboard-key:not(.guac-keyboard-uses-alt-gr):not(.guac-keyboard-key-alt-gr) {
    opacity: 0.5;
}
